import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import JumboPhoto from "../../../components/jumbophoto"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ContactForm from "../../../components/contactForm"

export default () => <Layout>
	<Helmet title="Bankruptcy Law Practice | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto />

	<Container>
		<Row>
			<Col>
				<h1>Understanding Bankruptcy</h1>

				<p>Have you done everything to try and pay off your debt? Are you looking for a way to <Link to="/practice-areas/bankruptcy-law/foreclosure">stop foreclosure</Link> and keep your assets? Chapter 13 bankruptcy may be right for you. By filing for Chapter 13 bankruptcy you can stop harassing phone calls from creditors, stop foreclosure, and enter a fair and manageable payment plan for your debt. At the Birmingham offices of Gina H. McDonald &amp; Associates, we can answer your <Link to="/practice-areas/bankruptcy-law/chapter-13">questions about Chapter 13 bankruptcy</Link> today.</p>

				<h3>If you have questions about debt relief, please contact Gina H. McDonald &amp; Associates at (205) 982-3325.</h3>
				<p>It is important to know what you can expect from the Chapter 13 bankruptcy process. After you file for Chapter 13 bankruptcy, you will have to attend a meeting of the creditors six weeks later. This will be followed by one more mandatory court date, which is a confirmation hearing with a judge.</p>
				<p>You will then be entered into a debt repayment plan that will last between three and five years. The payments you make will be based on the amount of debt, not how much you make. You will make payments every 30 days, for the length of the plan.</p>

				<h3>Advantages of Chapter 13 Bankruptcy</h3>
				<p>Chapter 13 bankruptcy is a great way to retain your assets while paying off your debt. By reorganizing your secure and unsecured debt into one payment plan, you can stop harassment from creditors, make payments you can afford, and keep your home. When the plan ends, your debt will be completely discharged in most cases.</p><h3>Important Bankruptcy Information</h3><p>There is certain information we must evaluate in order to help you file for bankruptcy. If you are considering Chapter 13 bankruptcy, you should start thinking about organizing this information. The sooner we get it, the sooner we can help you get the debt relief you deserve:</p>

				<ul>
					<li>Driver’s license, social security card</li>
					<li>Two years' filed tax returns (most currently filed return and last return filed prior to that)</li>
					<li>Six months of previous payroll and pay stubs</li>
					<li>Six months of bank statements prior to visiting our office, to include every bank/investment statement you have any interest in</li>
					<li>Tax assessment of your real property</li>
					<li>All of your bills – medical bills, credit card bills, etc.</li>
					<li>Judgments and pending lawsuits against you</li>
					<li>Any information of lawsuits you are pursuing</li>
					<li>Vehicle information and proof of insurance</li>
				</ul>

				<h3>Contact Us</h3>
				<p>You don't have to live under the pressure of mounting debt. At the Birmingham, Alabama, offices of Gina H. McDonald &amp; Associates, we can help you pay off your debt and keep your assets. Contact us today for an initial consultation.</p>
				<p><strong>We are a debt relief agency. We help people file for debt relief under the Bankruptcy Code.</strong></p>
				<p className="small text-muted">The following language is required pursuant to Rule 7.2, Alabama Rules of Professional Conduct. No representation is made that the quality of legal services to be performed is greater than the quality of legal services performed by other lawyers. This web site is designed for general information only. The information presented at this site should not be construed to be formal legal advice nor the formation of a lawyer/client relationship.</p>

			</Col>

			<Col md="4">

				{/* <section className="border rounded p-2 mt-2">
					Categories
				<ul>
						<li><Link to="/practice-areas/bankruptcy-law/chapter-7">Chapter 7</Link></li>
						<li><Link to="/practice-areas/bankruptcy-law/chapter-11">Chapter 11</Link></li>
						<li><Link to="/practice-areas/bankruptcy-law/chapter-13">Chapter 13</Link></li>
						<li><Link to="/practice-areas/bankruptcy-law/bankruptcy-and-divorce">Bankruptcy and Divorce</Link></li>
						<li><Link to="/practice-areas/bankruptcy-law/costs">Bankruptcy Costs</Link></li>
						<li><Link to="/practice-areas/bankruptcy-law/can-i-keep-my-car">Can I keep my car in bankruptcy?</Link></li>
						<li><Link to="/practice-areas/bankruptcy-law/filing-bankruptcy-in-alabama">Filing bankruptcy in Alabama</Link></li>
						<li><Link to="/practice-areas/bankruptcy-law/foreclosure">Stop Foreclosure</Link></li>
					</ul>
				</section> */}

				<div className="card my-2">
					<div className="card-header bg-primary text-white">
					Contact Us
  </div>
  <div class="card-body">
  <ContactForm />
  </div>
				</div>

				<div className="card my-2">
					<div className="card-header">
						Categories
  </div>
					<ul className="list-group list-group-flush">
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/chapter-7">Chapter 7</Link></li>
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/chapter-11">Chapter 11</Link></li>
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/chapter-13">Chapter 13</Link></li>
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/bankruptcy-and-divorce">Bankruptcy and Divorce</Link></li>
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/costs">Bankruptcy Costs</Link></li>
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/can-i-keep-my-car">Can I keep my car in bankruptcy?</Link></li>
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/filing-bankruptcy-in-alabama">Filing bankruptcy in Alabama</Link></li>
						<li className="list-group-item list-group-item-action"><Link to="/practice-areas/bankruptcy-law/foreclosure">Stop Foreclosure</Link></li>
					</ul>
				</div>
			</Col>
		</Row>

		<Row className="d-none">
			<Col>
				Bankruptcy can be a valuable tool to help Americans alleviate the burden of overwhelming debt. For many, bankruptcy conjures images of forced sales and the forfeiting of personal property. Liquidation, the defining process of Chapter 7 bankruptcy, does involve the sale of valuable assets. However, Chapter 13 bankruptcy is an entirely different animal, and may be a much more desirable option for those with significant equity in their home, a steady income or other property.

				How It Works

				In a Chapter 13 bankruptcy proceeding, debts are restructured to make repayment a more viable option. Usually, this that means the court will approve terms more favorable to the debtor, such as reducing or eliminating interest and lowering monthly installments. Under a court-confirmed repayment plan, the debtor will make regular payments for three to five years; after successfully completing the plan, any remaining balance on most types of debt will be discharged.

				The first step in the Chapter 13 process is filing a petition with the local bankruptcy court. Along with the petition, a number of documents detailing the financial status of the debtor must be filed, including lists of assets, liabilities, income and expenses. A certificate of credit counseling and recent tax returns are also required. All of this information will be analyzed by the court in determining whether to approve the terms of a repayment plan and to ensure that the debtor meets the eligibility requirements for Chapter 13 bankruptcy.

				Advantages

				Filing for Chapter 13 bankruptcy can aid debtors in a variety of ways. Unlike other forms of consumer bankruptcy, Chapter 13 allows individuals to avoid foreclosure on their homes. Payments on many kinds of debt are often lowered and consolidated once a payment plan is approved. In addition, creditors will be prevented from direct contact with anyone going through Chapter 13 bankruptcy. Finally, Chapter 13 has special provisions that may insulate cosigners from liability.

				For many individuals facing the burden of debt, Chapter 13 bankruptcy is an excellent option. To find out if Chapter 13 is the best option for you and to get the process in motion, consult an experienced bankruptcy attorney.
			</Col>
		</Row>
	</Container>
</Layout>